.App {
  text-align: center;
}
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.container {
  width: 100%;
  max-width: 460px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.header {
  text-align: center;
  padding: 20px;
  background-color: #4654a3;
  color: #fff;
}
.product-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}
.product-name {
  font-size: 1.8rem;
  font-weight: bold;
}
.verification-status {
  font-size: 1.4rem;
  color: #28a745;
  margin-top: 10px;
}
.product-details {
  padding: 20px;
  background-color: #fff;
  color: #333;
  font-size: 1.2rem;
}
.detail {
  margin-bottom: 15px;
}
.verification-code {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
}
.footer {
  text-align: center;
  padding: 20px;
  background-color: #4654a3;
  color: #fff;
}
.brand-logo {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}
.contact-support {
  display: inline-block;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  font-size: 1.1rem;
}
.report-robo{
  display: inline-block;
  background-color: #eb5449;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 10px;
  font-size: 1.1rem;
}
.contact-support:hover {
  background-color: #218838;
}